$uib-size: 100px;
$uib-speed: .8s;
$uib-color: #120B3C;

.three {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7fbfa;
  z-index: 100;

  &__body {
    display: flex;
    position: relative;
    top: 50%;
    left: 50%;
    width: $uib-size;
    height: $uib-size;
    margin: rem(170) 0;
    transform: translate(-100%, -100%);
    animation: spin78236 calc($uib-speed * 2.5) infinite linear;

    & .three__bodyDot {
      position: absolute;
      width: 30%;
      height: 100%;

      &::after {
        position: absolute;
        width: 100%;
        height: 0%;
        padding-bottom: 100%;
        border-radius: 50%;
        background-color: $uib-color;
        content: '';
      }

      &:nth-child(1) {
        bottom: 5%;
        left: 0;
        transform: rotate(60deg);
        transform-origin: 50% 85%;
      }

      &:nth-child(1)::after {
        bottom: 0;
        left: 0;
        animation: wobble1 $uib-speed infinite ease-in-out;
        animation-delay: calc($uib-speed * -.3);
      }

      &:nth-child(2) {
        right: 0;
        bottom: 5%;
        transform: rotate(-60deg);
        transform-origin: 50% 85%;
      }

      &:nth-child(2)::after {
        bottom: 0;
        left: 0;
        animation: wobble1 $uib-speed infinite calc($uib-speed * -.15) ease-in-out;
      }

      &:nth-child(3) {
        bottom: -5%;
        left: 0;
        transform: translateX(116.666%);
      }

      &:nth-child(3)::after {
        top: 0;
        left: 0;
        animation: wobble2 $uib-speed infinite ease-in-out;
      }
    }
  }
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(.65);
    opacity: .8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(.65);
    opacity: .8;
  }
}