.aboutUs {
  padding: 3rem 0;
  background-color: rgb(242, 242, 242);

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    flex-wrap: wrap;

    @media (min-width: 820px) {
      gap: initial;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
    padding: 0 5px;
    margin-top: 10px;
  }

  .title {
    font-family: 'Roboto', sans-serif;
    font-size: 44px;
    color: rgb(30, 29, 64);
    letter-spacing: 0em;
    margin: 0 auto;
  }

  .subtitle {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    font-size: 28px;
    font-weight: 500;
    color: rgb(12, 29, 57);
    font-style: normal;
  }

  .paragraphs {
    font-size: 21px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0em;
    text-align: left;
  }

  .statistics {
    display: grid;
    grid-template-areas:
      'area1 area1'
      'area2 area3'
      'area4 area4';
      // 'area5 area5';
    row-gap: 20px;
    align-content: center;
    height: auto;
    // min-height: 602px;
    grid-template-rows: min-content;
    justify-items: center;
    margin-bottom: 20px;
  }

  .area1 {
    grid-area: area1;
  }

  .area2 {
    grid-area: area2;
  }

  .area3 {
    grid-area: area3;
  }

  .area4 {
    grid-area: area4;
  }

  // .area5 {
  //   grid-area: area5;
  // }

  .statItem {
    height: auto;
    // width: 183px;
    display: grid;
    min-height: auto;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 100%;
    justify-items: center;
  }

  .statValue {
    padding: 9px 0;
    margin-bottom: 10px;
    width: 120px;
    background-color: rgba(30, 29, 64, 1);
    border: rgba(50, 65, 88, 1);
    border-radius: 10px;
    bottom: 0;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .6);
    font-size: 27px;
    text-align: center;
    color: white;
    font-weight: bold;

  }

  // .statValue_plus::after {
  //   content: '+';
  // }
  .statLabel {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
  }

  @media (min-width: 568px) {
    .statistics {
      grid-template-areas:
        'area1 area1 area2 area2 '
        ' area3 area3 area4 area4 ';
    }

    .content {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 820px) {

    .container {
      padding: 0 50px;
      flex-wrap: nowrap;
    }

    .content {
      gap: 44px;
      flex: 0 1 50%;
      padding: 0 15px;
    }

    .title {
      font-size: 59px;
    }

    .subtitle {
      font-size: 34px;
      font-weight: bold;
    }

    .statistics {
      row-gap: 54px;
      flex: 0 1 50%;
      min-height: 602px;
      column-gap: 10px;
    }

    .statValue {
      font-size: 39px;
    }

    .area4 .statValue {
      font-size: 29px;
      padding: 14px 0;
    }

    .area5 .statValue {
      font-size: 29px;
      padding: 14px 2px;
    }

  }

  @media (min-width: 993px) {
    .container {
      width: 980px;
      padding: 0;
    }
  }

}