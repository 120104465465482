.selector {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  box-sizing: border-box;

  &__flag {
    width: 1rem;
    height: 1rem;
  }

  &__btn {
    cursor: pointer;
  }

  &__list {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    right: calc(100% + 1rem);
    bottom: 0;
    padding: 10px;
    border: 2px solid white;
    border-radius: 10px;
    background: rgb(30, 29, 64);
    color: white;

    @media (min-width: 768px) {
      right: -12px;
      top: 2rem;
      width: max-content;
      height: max-content;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__country {
    padding: 5px 0 5px 5px;
    text-align: right;
    cursor: pointer;
  }
}