.footer {
  padding: 2rem 0;
  background-color: rgb(13, 11, 64);
  color: rgb(242, 242, 242);
  font-family: "Roboto", Sans-Serif;

  &__container {
    min-width: 320px;
    max-width: 1470px;
    margin: 0 auto;
    padding: 20px 15px;
    box-sizing: border-box;
  }

  &__title {
    font-size: 30px;
    font-weight: normal;
    padding-bottom: 20px;
  }

  &__info {
    position: relative;
    padding: 2rem 0;

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #f92e3e;
      content: '';
    }

    &::before {
      top: 0;
    }
    
    &::after {
      bottom: 15px;
    }
  }

  &__infoTitle {
    font-size: 19px;
    letter-spacing: 0.05em;
    padding-bottom: 10px;
  }

  &__infoText {
    font-size: 15px;
  }

  &__infoText:not(:last-child) {
    padding-bottom: 20px;
  }

  &__copyright {
    max-width: 240px;
    margin-top: 20px;
    letter-spacing: 0.01em;
    line-height: 1.5em;
    font-size: 13px;
  }

  &__devinfo {
    font-size: 13px;
    color: #CDD1DA;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
}

@media (min-width: 768px) {
  .footer {

    &__container {
      padding: 20px 50px;
    }

    &__title {
      font-size: 50px;
    }

    &__infoTitle {
      font-size: 29px;
      letter-spacing: 0.05em;
    }

    &__infoText {
      max-width: 100%;
      font-size: 20px;
    }

    &__copyright {
      max-width: 100%;
      font-size: 14px;
    }
  }
}

@media (min-width: 993px) {
  .footer {

    &__container {
      width: 980px;
      padding: 20px 0;
    }
  }
}