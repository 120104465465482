.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #202025;
  font-size: 14px;
  // background-color: #120B3C;
  background: linear-gradient(rgba(12, 29, 57, 0.43), rgba(12, 29, 57, 0.43));
  z-index: 28;
  transition: all .4s ease-out;
  opacity: 1;
  border-radius: 50px;


  &.scrolled {
    visibility: hidden;
    opacity: 0;
  }

  @media (min-width: 821px) {
    background: transparent;

    &.scrolled {
      opacity: 1;
    }
  }

  .wrapper {
    transition: all .4s ease-out;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 320px;
    max-width: 1470px;
    margin: 0 auto;
    padding: 5px 15px;
    box-sizing: border-box;
  }

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 30%;
    
    @media (min-width: 821px) {
      flex: initial;
    }
  }

  .section>a {
    flex: 0 1 40%;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo>img {
    width: 80px;

    @media (min-width: 768px) {
      width: 100px;
    }
  }

  // .title {
  //   display: none;

  //   @media (min-width: 481px) {
  //     display: block;
  //     margin-left: 12px;
  //     color: #F24405;
  //     font-size: 24px;
  //     font-weight: 900;
  //     line-height: 167%;
  //     text-decoration: none;
  //   }
  // }

  .nav__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    flex: 1 0 auto;

    @media (min-width: 821px) {
      flex: initial;
      gap: 1.5rem;
      background: linear-gradient(rgba(12, 29, 57, 0.43), rgba(12, 29, 57, 0.43));
      padding: 0 20px;
      // margin-right: 20px;
      justify-content: flex-end;
    }
  }

  .nav {
    display: none;

    @media (min-width: 821px) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      color: white;
      // padding: 0 20px;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    list-style: none;
    padding: 0;
  }

  .listItem {
    &_active>button {
      color: #f92e3e;
    }

    &>button {
      cursor: pointer;
      transition: color .4s ease-out;

      &:hover {
        color: #f92e3e;
      }
    }
  }

  .nav_login {
    color: white;
    // margin-right: 20px;
    background-color: #f92e3e;
    border-radius: 8px;
    padding: 3px 15px;
    transition: all 0.2s ease, visibility 0s;

    &:hover {
      background-color: #120B3C;
    }
  }

}