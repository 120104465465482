.signUp {
    min-width: 320px;
    font-family: 'Roboto', sans-serif;
  
    &__wrapper {
      position: relative;
      background: url('../../../public/images/signup_bg.webp') no-repeat center top/ cover;
      background-attachment: fixed;
    
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(174 194 217 / 26%);
      }
    }
  
    &__container {
      max-width: 37rem;
      min-width: 320px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      z-index: 1;
      padding-bottom: 60px;
    }
  
    &__title {
      margin: 40px 0px 50px 0;
      padding: 0 10px;
      font-size: 30px;
      line-height: 1.4em;
      text-align: center;
      font-weight: 500;

      @media (min-width: 768px) {
        font-size: 39px;
      }
    }

    &__form {
      padding: 0 15px;
      @media (min-width: 640px) {
        padding: 0;
      }
    }

    &__errorMessage {
      color: #f92e3e;;
      position: absolute;
      bottom: 1%;
      font-size: 14px;
    }

    &__successMessage {
      color: #f92e3e;;
      position: absolute;
      bottom: 1%;
      font-size: 14px;
    }
  }